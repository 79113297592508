import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

// Locale
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-AR';
import { getEspPaginatorIntl } from './auxiliares/paginator-intl';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { AuxiliaresModule } from './auxiliares/auxiliares.module';
import { PwaService } from './auxiliares/pwa.service';
import { MatPaginatorIntl } from '@angular/material/paginator';

registerLocaleData(localeEs, 'es-AR');
//

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // LayoutModule,
    AuxiliaresModule,
    ServiceWorkerModule.register('./ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: MatPaginatorIntl, useValue: getEspPaginatorIntl() },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(public pwaService: PwaService) { }
}
