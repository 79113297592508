import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './auxiliares/guards/loging.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () =>
      import('./modulos/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'main',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./modulos/navigation/module').then((m) => m.NavigationModule),
  },
  {
    path: 'offline',
    canActivate: [],
    loadChildren: () =>
      import('./modulos/offline/module').then((m) => m.OfflineModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
